var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flight-wrapper" }, [
    _c("p", { staticClass: "flight-intro" }, [
      _vm._v(
        " Live " +
          _vm._s(_vm.direction !== "both" ? _vm.direction : "flight") +
          " data from " +
          _vm._s(_vm.airport) +
          " "
      ),
    ]),
    _vm.departures.length > 0
      ? _c(
          "div",
          { staticClass: "flight-group" },
          [
            _vm.direction === "both"
              ? _c("h3", { staticClass: "flight-type-subtitle" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("widgets.flight-data.departures")) + " "
                  ),
                ])
              : _vm._e(),
            _vm._l(_vm.departures, function (flight) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.tip(flight),
                      expression: "tip(flight)",
                    },
                  ],
                  key: flight.number,
                  staticClass: "flight",
                },
                [
                  _c("p", { staticClass: "info flight-time" }, [
                    _vm._v(_vm._s(_vm._f("formatDate")(flight.time))),
                  ]),
                  _c("p", { staticClass: "info flight-number" }, [
                    _vm._v(_vm._s(flight.number)),
                  ]),
                  _c("p", { staticClass: "info flight-airport" }, [
                    _vm._v(_vm._s(flight.airport)),
                  ]),
                ]
              )
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.arrivals.length > 0
      ? _c(
          "div",
          { staticClass: "flight-group" },
          [
            _vm.direction === "both"
              ? _c("h3", { staticClass: "flight-type-subtitle" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("widgets.flight-data.arrivals")) + " "
                  ),
                ])
              : _vm._e(),
            _vm._l(_vm.arrivals, function (flight) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.tip(flight),
                      expression: "tip(flight)",
                    },
                  ],
                  key: flight.number,
                  staticClass: "flight",
                },
                [
                  _c("p", { staticClass: "info flight-time" }, [
                    _vm._v(_vm._s(_vm._f("formatDate")(flight.time))),
                  ]),
                  _c("p", { staticClass: "info flight-number" }, [
                    _vm._v(_vm._s(flight.number)),
                  ]),
                  _c("p", { staticClass: "info flight-airport" }, [
                    _vm._v(_vm._s(flight.airport)),
                  ]),
                ]
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }